import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Tag,
  Descriptions,
  Image,
  Avatar,
  Badge,
  Table,
  Button,
  Modal,
  DatePicker,
  Select,
  notification,
  Steps
} from "antd";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { FaCoins } from "react-icons/fa";
import coin_pic from "../../assets/coin_pic.png";
import moment from "moment";
import CustomPopupConfirm from "./CustomPopupConfirm";
import api from "../../commonFuncs/api";
const { MonthPicker, RangePicker } = DatePicker;
const { Step } = Steps;

const { Option } = Select; 
const dateFormat = "MM/DD";
let defaultSelectedValue = 5;

const ViewTfl = () => {
  let location = useLocation();
  let navigate = useNavigate();
  let data = location.state.record;
  let coinsVal = location.state.record.pointsConvVal
    ? location.state.record.pointsConvVal
    : 0;
  console.log("coinsVal", coinsVal);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isPopup, setPopup] = useState(false);
  const [Id, setId] = useState(data._id);
  const [deliveryWithIn, setDeliveryWithIn] = useState("7-8 Days");

  useEffect(() => {
    setDeliveryWithIn("7-8 Days");
  }, []);

  const IncompeleteOrder = async (data) => {
    try {
      console.log("api data", data);
      let result = await api.post(
        `/api/v1/treeDriveAdmin/appsettings/confirmTflOrders`,
        data
      );
      console.log("result in oppogjor=====>", result.data.data);
      setIsModalVisible(false);
      if (result.status == 200) {
        notification.open({
          message: "Order confirmed successfully!",
          duration: 3
        });
        navigate("/tflOrders");
      }
    } catch (err) {
      console.log("error--->", err.message);
    }
  };

  let dataArr = [];
  dataArr.push(data);

  let totalProductPrice = 0;
  data.items.map((e) => {
    totalProductPrice += e.totalAmount;
  });

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    console.log("date yuttttttttttttt====== ");
    // sending  payload
    let postData = {};
    postData = {
      orderId: data._id,
      orderStatus: "SHIPPED",
      deliveryWithIn: deliveryWithIn ? deliveryWithIn : "7 - 8 Days",
      cancelReason: ""
    };
    // }

    console.log("post data ----", postData);
    IncompeleteOrder(postData);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const inProcessFxn = () => {
    let postData = {};
    postData = {
      orderId: data._id,
      orderStatus: "DELIVERED",
      deliveryWithIn: "",
      cancelReason: ""
    };
    // }

    console.log("post data ----", postData);
    IncompeleteOrder(postData);
  };

  const inProcessFxn2 = () => {
    let postData = {};
    postData = {
      orderId: data._id,
      orderStatus: "IN-PROCESS",
      deliveryWithIn: "",
      cancelReason: ""
    };
    // }

    console.log("post data ----", postData);
    IncompeleteOrder(postData);
  };

  const columns = () => [
    {
      title: "S.No",
      key: "orderNumber",
      width: "5%",
      fixed: "center",
      render: (record, item, index) => {
        return <>{index + 1}</>;
      }
    },
    {
      title: "Product Name",
      key: "Product Name",
      width: "15%",
      render: (text, record, index) => {
        return <span>{record.name}</span>;
      }
    },
    {
      title: "Total Dimension",
      key: "price",
      width: "20%",
      render: (text, record) => {
        return (
          <>
            Total length : {record.totalLength}
            <br />
            Total Breadth : {record.totalBreadth}
            <br />
            Total Height : {record.totalHeight}
          </>
        );
      }
    },
    {
      title: "Total Weight",
      key: "price",
      width: "15%",
      render: (text, record) => `${record.totalWeight / 1000} kg`
    },
    {
      title: "Price",
      key: "price",
      width: "10%",
      render: (text, record) => `Kr. ${record.amount}`
    },
    {
      title: "Quantity",
      key: "Quantity",
      width: "10%",
      render: (text, record) => `${record.quantity}`
    },
    {
      title: "Total Price",
      key: "Total Price",
      width: "20%",
      render: (text, record) => {
        return <span> Kr. {record.totalAmount}</span>;
      }
    }
  ];

  const columns2 = (totalProductPrice, data) => [
    {
      title: "Product Total Price",
      key: "Product Name",
      // width: "15%",
      render: () => `Kr. ${totalProductPrice.toFixed(2)}`
    },
    {
      title: "Delivery Charges",
      key: "price",
      render: (text, record) => `Kr. ${record.deliveryCharges}`
    },
    {
      //title: `Discount ( ${data.coinsUsed ? data.coinsUsed : 0} ${<FaCoins />} used)`,
      title: (
        <span>
          Discount
          <span className="ml-2">{data.coinsUsed ? data.coinsUsed : 0}</span>
          <img
            src={coin_pic}
            style={{ width: "16px", marginTop: "-5px" }}
            className="mr-1 ml-1"
          />
          used
        </span>
      ),
      key: "discount",
      render: (text, record) => `Kr. ${record.discount ? record.discount : 0}`
    },
    {
      title: "Total Price",
      key: "Total Price",
      render: (text, record) => {
        return <span> Kr. {record.totalAmount}</span>;
      }
    }
  ];

  const modalFooter = (
    <div
      className="modelFooter"
      style={{ display: "flex", justifyContent: "right" }}
    >
      <Button type="primary" className="float-right" onClick={handleOk}>
        OK
      </Button>
    </div>
  );

  // ============= model open when click on delete btn =================== //

  const showConformationDialog = (id) => {
    setId(id);
    setPopup(true);
  };
  const cancelConformationDialog = () => {
    setPopup(false);
  };

  const numberRange = Array.from({ length: 30 }, (_, index) => index + 1); // Example: Range from 1 to 30

  const onChange2 = (e) => {
    let delivery = `${e} - ${e + 1} Days`;
    setDeliveryWithIn(delivery);
    console.log("Selected value:", delivery);
  };

  return (
    <div className="viewTfl">
      <div className="add-r-main bg-white pb-2">
        <Row className="justify-content-between align-items-center add-r-head px-4 py-3">
          <Col>
            {data.orderStatus == "DELIVERED" ? (
              <div className="d-flex pt-2">
                <Tag
                  color="green"
                  style={{ fontSize: "16px", padding: "10px" }}
                >
                  Order Delivered successfully
                </Tag>
              </div>
            ) : data.orderStatus == "CANCEL" ? (
              <div className="d-flex pt-2">
                <Tag color="red" style={{ fontSize: "16px", padding: "10px" }}>
                  Cancel Reason : {data.cancelReason}
                </Tag>
              </div>
            ) : (
              <h3>Order Detail</h3>
            )}
          </Col>
          <Col className="flex justify-content-end">
            <NavLink to="/tflOrders" className="common-btn">
              Return to the List
            </NavLink>
          </Col>
        </Row>
        <Row gutter={24} className=" flex display-data">
          <Col span={12}>
            {" "}
            <h4>Product Information : </h4>
          </Col>
          <Col span={12}>
            <p style={{ textAlign: "end", marginRight: "10px" }}>
              1
              <img
                src={coin_pic}
                style={{ width: "16px", marginTop: "-5px" }}
                className="mr-1 "
              />{" "}
              = Kr. {coinsVal}{" "}
            </p>
          </Col>
          <Table
            scroll={{ x: true }}
            className="user-table2"
            bordered
            dataSource={data.items}
            pagination={false}
            columns={columns()}
          ></Table>
          <Table
            scroll={{ x: true }}
            className="user-table2 mt-4"
            bordered
            dataSource={dataArr}
            pagination={false}
            columns={columns2(totalProductPrice, data)}
          ></Table>
        </Row>

        <Row className="justify-content-center">
          {" "}
          {data.orderStatus == "PENDING" ? (
            <>
              <Button className="common-btn3" onClick={inProcessFxn2}>
                Confirm Order
              </Button>
              <Button
                className="common-btn3"
                onClick={() => showConformationDialog(data._id)}
              >
                Cancel Order
              </Button>
            </>
          ) : (
            ""
          )}
          {data.orderStatus == "IN-PROCESS" ? (
            <>
              <Button className="common-btn3" onClick={showModal}>
                Shipped Order
              </Button>
              <Button
                className="common-btn3"
                onClick={() => showConformationDialog(data._id)}
              >
                Cancel Order
              </Button>
            </>
          ) : (
            ""
          )}
          {data.orderStatus == "SHIPPED" ? (
            <>
              <Button className="common-btn3" onClick={inProcessFxn}>
                Handover
              </Button>
              <Button
                className="common-btn3"
                onClick={() => showConformationDialog(data._id)}
              >
                Cancel Order
              </Button>
            </>
          ) : (
            ""
          )}
        </Row>
        <Row className="px-5">
          <p
            className="my-4"
          >
            Order Status :{" "}
          </p>{" "}
          {data.orderStatus == "DELIVERED" ? (
            <>
              <Steps
                className="custom-steps"
                progressDot
                size="small"
                current={4}
              >
                {/* <span
                  style={{
                    color: "BLACK",
                    fontSize: "16px",
                    fontWeight: "700"
                  }}
                  className="pr-3"
                >
                  Order Status :{" "}
                </span> */}
                <Step title="PENDING" />
                <Step title="IN-PROCESS" />
                <Step title="SHIPPED" />
                <Step title="DELIVERED" />
              </Steps>
            </>
          ) : data.orderStatus == "CANCEL" ? (
            <>
              <Steps status="error" size="small" current={1}>
                {/* <span
                  style={{
                    color: "BLACK",
                    fontSize: "16px",
                    fontWeight: "700"
                  }}
                  className="pr-3"
                >
                  Order Status :{" "}
                </span> */}
                <Step title="CANCEL" />
              </Steps>
            </>
          ) : data.orderStatus == "SHIPPED" ? (
            <>
              <Steps
                style={{ color: "red" }}
                progressDot
                size="small"
                current={3}
              >
                {/* <span
                  style={{
                    color: "BLACK",
                    fontSize: "16px",
                    fontWeight: "700"
                  }}
                  className="pr-3"
                >
                  Order Status :{" "}
                </span> */}
                <Step title="PENDING" />
                <Step title="IN-PROCESS" />
                <Step title="SHIPPED" />
                <Step title="DELIVERED" />
              </Steps>
            </>
          ) : data.orderStatus == "PENDING" ? (
            <>
              <Steps
                style={{ color: "red" }}
                progressDot
                size="small"
                current={1}
              >
                {/* <span
                  style={{
                    color: "BLACK",
                    fontSize: "16px",
                    fontWeight: "700"
                  }}
                  className="pr-3"
                >
                  Order Status :{" "}
                </span> */}
                <Step title="PENDING" />
                <Step title="IN-PROCESS" />
                <Step title="SHIPPED" />
                <Step title="DELIVERED" />
              </Steps>
            </>
          ) : data.orderStatus == "IN-PROCESS" ? (
            <>
              <Steps
                style={{ color: "red" }}
                progressDot
                size="small"
                current={2}
              >
                {/* <span
                  style={{
                    color: "BLACK",
                    fontSize: "16px",
                    fontWeight: "700"
                  }}
                  className="pr-3"
                >
                  Order Status :{" "}
                </span> */}
                <Step title="PENDING" />
                <Step title="IN-PROCESS" />
                <Step title="SHIPPED" />
                <Step title="DELIVERED" />
              </Steps>
            </>
          ) : (
            ""
          )}
        </Row>
        <Row className=" flex flex-column px-5 pt-5">
          <div className="display-data flex flex-column  justify-content-center">
            <h4>User Information : </h4>
            <Descriptions layout="vertical" bordered className="pt-3">
              <Descriptions.Item label="Name">
                {Object.keys(data).length > 0 ? data.customerDetail.name : ""}
              </Descriptions.Item>
              <Descriptions.Item label="Email Id">
                {Object.keys(data).length > 0 ? data.customerDetail.email : ""}
              </Descriptions.Item>
              <Descriptions.Item label="Phone Number">
                +
                {Object.keys(data).length > 0
                  ? `${
                      data.customerDetail.countryCode +
                      " " +
                      data.customerDetail.phoneNo
                    }`
                  : ""}
              </Descriptions.Item>
              <Descriptions.Item label="Address">
                {Object.keys(data).length > 0
                  ? data.customerDetail.address
                  : ""}
              </Descriptions.Item>
            </Descriptions>
          </div>
        </Row>
      </div>

      <Modal
        title="Order Confirmation"
        visible={isModalVisible}
        // onOk={handleOk}
        onCancel={handleCancel}
        footer={modalFooter}
        cancelButtonProps={{ style: { display: "none" } }} // This hides the Cancel button
      >
        <div>
          {/* <DatePicker defaultValue={moment()} format={dateFormat} /> */}
          <p>
            Order Received On :{" "}
            <span className="confirm-span">
              {" "}
              {moment(data.createdAt).format("MMMM DD, YYYY")}
            </span>
          </p>
          <span>Order will be delivered within :</span>
          <Select
            className="confirm-span"
            defaultValue="7 - 8 Days"
            style={{ width: 120, marginLeft: "10px" }}
            onChange={onChange2}
          >
            {numberRange.map((num) => (
              <>
                <Option key={num} value={num}>
                  {num} - {num + 1} {"   "} Days
                </Option>
              </>
            ))}
          </Select>
          <br />
        </div>
      </Modal>

      <CustomPopupConfirm
        visible={isPopup}
        cancel={cancelConformationDialog}
        productId={Id}
      />
    </div>
  );
};

export default ViewTfl;
