import React, { useEffect, useState } from "react";
import { Input, Table, Button, notification, Row, Icon, Col } from "antd";
import api from "../../commonFuncs/api";
import { SmileOutlined, MehOutlined } from "@ant-design/icons";

const AddPoints = (props) => {
  const [points, setPoints] = useState("");
  const [nok, setNok] = useState("");

  const addValue = async () => {
    let payload = {};
    payload.points = points;
    payload.nok = nok;
    try {
      let result = await api.post(
        `/api/v1/treeDriveAdmin/appsettings/settings/addPoints`,
        payload
      );
      console.log("result", result);
      if (result.status == 200) {
        notification.open({
          message: "Added new point value successfully !",
          icon: (
            <SmileOutlined
              style={{
                color: "#108ee9"
              }}
            />
          )
        });
        props.indexPage();
      }
    } catch (error) {
      console.log("error", error);
      notification.open({
        message: "Added new point value successfully !",
        icon: (
          <MehOutlined
            style={{
              color: "#108ee9"
            }}
          />
        )
      });
    }
  };

  return (
    <div className="bg-white">
      <Row className="justify-content-between align-items-center add-r-head p-2">
        <Col xs={10} sm={10} md={12} lg={12} xl={12}>
          {" "}
          <h4 className="mb-0">Add Coins</h4>
        </Col>
        <Col xs={14} sm={14} md={12} lg={12} xl={12} className="text-right">
          <Button onClick={() => props.indexPage()} className="common-btn2">
            Return to the List
          </Button>
        </Col>
      </Row>
      <Row gutter={24} className="justify-content-center m-3">
        <Col
          xs={24}
          sm={24}
          md={12}
          lg={12}
          xl={12}
          className="flex flex-column mb-2"
        >
          <p className="mb-0">Add Coins:</p>{" "}
          <Input
            onChange={(e) => setPoints(e.target.value)}
            value={points}
            placeholder="Add Coins"
            type="Number"
          />
        </Col>
        <Col
          xs={24}
          sm={24}
          md={12}
          lg={12}
          xl={12}
          className="flex flex-column"
        >
          <p className="mb-0">Add NOK:</p>{" "}
          <Input
            onChange={(e) => setNok(e.target.value)}
            value={nok}
            placeholder="Add NOK"
            type="Number"
          />
        </Col>
        <Col span={24} className="flex justify-content-center">
          {" "}
          <Button
            type="primary"
            className="city-search m-4"
            onClick={() => addValue()}
          >
            ADD
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default AddPoints;
